import { Endpoint } from "../Endpoint";
import API from "../API";

const name = "/timereport";

enum TimeReportStatus {
  // Pending=responder has not yet reported time
  pending = "pending",

  // Unapproved=responder has reported time, but it has not yet been approved by requester
  unapproved = "unapproved",

  // Approved=the requester has approved the time reported by responder
  approved = "approved",
}

//---Post
interface PostRequestBody {
  requestId: string;
  responderId: string;
  hoursWorked?: number;
  requesterComment?: string;
}

type PostResponseBody = "OK";

//---Get
interface GetParams {
  responderId?: string;
  requestId?: string;
  requesterComment?: string;
}

type GetResponseBody = Array<TimeReport>;

interface TimeReport {
  request: Request;
  responder: Responder;
  hoursWorked: number;
  status: TimeReportStatus;
  requesterComment?: string;
}

//---Put
interface PutRequestBody {
  requestId: string;
  responderId: string;
  operation: PutOperation;
  hoursWorked?: number;
  requesterComment?: string;
}

type PutResponseBody = "OK";

//The operation to use in a PUT command
enum PutOperation {
  //For responders: report a time and flag that you want the time report approved
  reporting = "reporting",
  // For requester: send back time report for responder to rewrite
  reviewing = "reviewing",
  //For requesters: approve a time report
  approving = "approving",
}

interface Request {
  id: string;
  title: string;
  description: string;
  startTime: Date;
  duration: number;
  yearExperience: number;
  createdAt: Date;
  requester: Requester;
  compensation: number;
  payMargin: number;
}

interface Requester {
  id: string;
  name: string;
}

interface Responder {
  name: string;
  experience: number;
  id: string;
  skills: Array<Skill>;
}

interface Skill {
  id: String;
}

export {
  name,
  TimeReport,
  TimeReportStatus,
  PostRequestBody,
  PostResponseBody,
  PutRequestBody,
  PutResponseBody,
  PutOperation,
  GetParams,
  GetResponseBody,
};

export default class TimeReportEndpoint extends Endpoint {
  constructor(api: API, parent: Endpoint | null = null) {
    super(api, name, parent);
  }

  post(body: PostRequestBody) {
    return this.sendPost<PostResponseBody>(body);
  }

  put(body: PutRequestBody) {
    return this.sendPut<PutResponseBody>(body);
  }

  get(queryParams: GetParams) {
    return this.sendGet<GetResponseBody>(undefined, queryParams);
  }
}
